//import 'alpinejs';
//console.log("JS LOADED")
import 'mmenu-js';
import GLightbox from 'glightbox';//Lightbox
const lightbox = GLightbox({
  touchNavigation: true,
  selector: 'data-lightbox',
  videosWidth: '80vw'
});
console.log("JS LOADED")

var Flickity = require('flickity');

// Mmmenu Configuration
document.addEventListener(
  "DOMContentLoaded", () => {
    const menu = new Mmenu("#mobile-menu", {
      extensions: ["position-right", "pagedim-black", "shadow-page", "theme-dark"],
      navbars: [{
        "position": "top",
        "content": [
          "<div style='padding: 30px; width: 60%; margin: 0 auto'><img style='max-width: 100%; max-height: 100%' src='assets/logo-primary.png'> </div>"
        ]
      }]
    });

  }
);